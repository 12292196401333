<template lang="pug">
app-layout
  v-app.layout-full
    app-nav-drawer(v-model="navDrawer")
    app-header(no-buttons @nav:click="navDrawer = true")

    v-main
      .page-sign-in.pa-6.pb-8
        .t-h1-mobile.mb-5 Изменение пароля
        v-text-field.mt-4(
          v-model="password"
          type="password"
          label="Новый пароль"
          hide-details="auto"
          key="password"
          :error-messages="errors.password"
        )
        v-text-field.mt-4(
          v-model="passwordConfirmation"
          type="password"
          label="Повторите пароль"
          hide-details="auto"
          key="passwordConfirmation"
          :error-messages="errors.password_confirmation"
        )
        v-messages.error--text.mt-2(
          v-if="errorMsg('reset_token')"
          :value="[errorMsg('reset_token')]"
        )

        ui-btn.mt-8(
          large
          key="btnUpdate"
          primary
          width="100%"
          @click="updatePassword"
          :loading="loading"
        ) Сохранить и войти
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';
  import AppFooter from '@/components/app/AppFooter';

  import { UiBtn, UiIcon } from '@/uikit';
  import CodeVerificationForm from '@/components/auth/CodeVerificationForm.vue';
  import EmailSignInForm from '@/components/auth/EmailSignInForm.vue';
  import SberIdBtn from '@/components/auth/SberIdBtn.vue';
  import SberBusinessIdBtn from '@/components/auth/SberBusinessIdBtn.vue';
  import EsiaBtn from '@/components/auth/EsiaBtn.vue';

  import { mapGetters, mapActions } from 'vuex';
  import { service as API } from '@/services/auth';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  import { formErrors } from '@/uikit/mixins/formErrors';
  const logger = createLogger('PageAuthPasswordReset', 'blue');

  export default {
    name: 'page-sign-in',
    components: {
      AppLayout,
      AppNavDrawer,
      AppHeader,
      AppFooter,
      UiBtn,
      UiIcon,
      CodeVerificationForm,
      EmailSignInForm,
      SberIdBtn,
      SberBusinessIdBtn,
      EsiaBtn,
    },
    mixins: [formErrors],

    data: () => ({
      prevPath: null,
      navDrawer: false,
      password: undefined,
      passwordConfirmation: undefined,
      loading: false,
    }),

    computed: {
      ...mapGetters(['currentUser']),
    },

    created() {
      this.reset();
    },

    methods: {
      validatePasswords() {
        if (this.password === undefined || this.password.length === 0)
          this.addError('не может быть пустым', 'password');
        if (this.passwordConfirmation === undefined || this.passwordConfirmation.length === 0)
          this.addError('не может быть пустым', 'password_confirmation');

        if (this.password !== this.passwordConfirmation)
          this.addError('пароли не совпадают', 'password_confirmation');
      },

      reset() {
        this.password = undefined;
        this.passwordConfirmation = undefined;
        this.loading = false;
        this.errors = {};
      },

      async updatePassword() {
        try {
          this.clearErrors();
          this.validatePasswords();
          if (this.hasAnyErrors) return;

          this.loading = true;
          const resetToken = this.$route.query.reset_token;
          const { token, refreshToken, user } = await API.updatePassword(
            this.password,
            this.passwordConfirmation,
            resetToken
          );
          this.reset();
          this.signInWithToken({ token, refreshToken, user });
          this.$router.push(this.$path.home());
        } catch (error) {
          handleError(error, logger);
          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(
              error.data,
              'password',
              'password_confirmation',
              'reset_token'
            );
          }
        } finally {
          this.loading = false;
        }
      },

      async sendCode() {
        try {
          await API.requestCode(this.phoneOrEmail);
          this.$notify.info('Код отправлен в СМС на указанный номер телефона');
        } catch (error) {
          handleError(error, logger);
        }
      },

      ...mapActions('user', ['signInWithToken']),
    },
  };
</script>

<style lang="scss">
  .page-sign-in {
    background-color: c('white', 'light');
  }
</style>
